<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="TruckIcon" size="21" />
      {{ $tc('Global.New', 2) }} {{ $t('Management.Areas.Area') }}
    </b-button>
    <Form
      ref="form"
      :countries="countries"
      :area="area"
      :can="$can($acl.action.Create, $acl.subjects.Areas)"
      :header="$tc('Global.New', 2) + ' ' + $t('Management.Areas.Area')"
      @submit="submit"
      @reset="reset"
      @openCountryModal="openCountryModal"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Form from './form.vue'

export default {
  components: {
    Form,
    BButton,
  },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    area: {
      name: null,
      short: null,
      country: null,
    },
  }),

  methods: {
    reset() {
      this.area = {
        name: null,
        short: null,
        country: null,
      }
    },
    openCountryModal() {
      this.$emit("openCountryModal");
    },
    submitCountry(entry){
      this.$emit("submitCountry", entry);
    },
    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        this.area.country = this.area.country.id
        const { data } = await this.$axios.post('areas', this.area)

        this.$alert.create(`${data.name}`)
        this.$emit('add', data)

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
