<template>
  <b-modal
    ref="modal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    class="modal-dialog modal-dialog-centered"
  >
    <template #modal-title>
      {{ $tc("Global.New", 2) }} {{ $t("Management.Countries.Country") }}
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="p-2"
      @submit.prevent="submit"
    >
      <section v-if="country">
        <b-form-group :label="$t('Management.Countries.Country')" label-for="country" >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Countries.Country')"
            rules="required"
          >
            <b-form-input 
              id="country"
              v-model="country.name"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Countries.Country'),
                })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Management.Countries.Short')"
          label-for="short"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Countries.Short')"
            rules="required"
          >
            <b-form-input
              id="short"
              v-model="country.short"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Countries.Short'),
                })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </section>
    </validation-observer>
    <template #modal-footer>
      <div class="buttons-container d-flex">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="submit"
        >
          {{ $t("Global.Save") }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="modalVisible = false"
        >
          {{ $t("Global.Retreat") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BButton, BFormInput, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { preventUnsavedChangesPopupLevel } from "@/utility/mixins/preventUnsavedChanges";
import { required } from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    BFormGroup,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    can: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    required,
    country: {
      name: null,
      short: null,
    },
  }),

  methods: {
    reset() {
      this.country = {
        name: null,
        short: null,
      }
      this.$refs.rules.reset();
    },
    async submit() {
      const valid = await this.$refs.rules.validate();

      if (valid) {
        const { data } = await this.$axios.post('countries', this.country);
        this.country = data;
        this.$alert.create(`${data.name}`);

        this.$emit("submitCountry", data);
        return;
      }
      this.$alert.invalid();
    },
  },
};
</script>
<style lang="scss">
.buttons-container {
  gap: 1rem;
}
</style>
